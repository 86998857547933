import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

/**
 * More info: https://medium.com/@fbanyai/swiper-10-angular-16-uncovered-1721bf32dfd6
 */

@Directive({
  selector: '[appSwiper]',
  standalone: true,
})
export class SwiperDirective implements AfterViewInit {
  @Input() config?: SwiperOptions;
  @Output() indexChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private el: ElementRef<SwiperContainer>,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      Object.assign(this.el.nativeElement, this.config);
      this.el.nativeElement.initialize();
      this.el.nativeElement.addEventListener(
        'swiperslidechange',
        (event: any) => {
          this.indexChange?.emit(event.detail[0].activeIndex);
        }
      );
    }
  }
}
